body {
    padding: 1rem;
    padding-bottom: 5rem;
}

.parent {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 0;
    grid-row-gap: 0;
}

.sky {
    grid-area: 1 / 4 / 5 / 6;
    border: 1px solid black;
}

.content {
    grid-area: 3 / 3 / 4 / 4;
    border: 1px solid green;
}

.bigize {
    grid-area: 1 / 1 / 2 / 4;
    border: 1px solid red;
}

.shortcode {
    grid-area: 4 / 1 / 5 / 2;
    border: 1px solid blue;
}

table {
    width: 100%;
    table-layout: auto;
    border: 1px solid black;
    border-collapse: collapse;

}

table thead {
    background-color: #ccc;
    font-weight: bold;
    text-align: left;
}

table td, table th {
    border: 1px solid black;
    padding: 5px;
    vertical-align: top;
}

textarea {
    width: 100%;
}

.current-page-filter label {
    width: 200px;
    display: inline-block;
}

.current-page-filter > * {
    padding: .5rem 0;
}

table select {
    width: 100%;
}

.booking-type-sky td {
    background-color: rgba(0, 0, 0, 0.1);
}

.booking-type-content td {
    background-color: rgba(0, 128, 0, 0.1);
}

.booking-type-bigsize td {
    background-color: rgba(255, 0, 0, 0.1);
}

.booking-type-shortcode td {
    background-color: rgba(0, 0, 255, 0.1);
}

.order-settings {
    margin: 1rem 0;
}

.order-setting {
    align-items: center;
    display: flex;
    margin: 1rem 0;
    width: 500px;
}

.order-setting > div:first-child {
    width: 200px;
}